@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.builderForm {
  padding-top: 72px;
}

.inner {
  background-color: var(--grey2);
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  padding: var(--sectionPaddingMedium);

  @include for-mobile-screens {
    flex-direction: column-reverse;
    padding: 24px;
  }
}

.column {
  width: 50%;
  overflow: hidden;

  @include for-mobile-screens {
    width: 100%;
  }
}

.leftColumnInner {
  max-width: 420px;
  margin-right: 24px;

  @include for-mobile-screens {
    margin-right: 0;
  }
}

.intro {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons {
  display: flex;
  gap: 40px;
  margin-bottom: 24px;
}

.title {
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 40px;
}

.animation {
  aspect-ratio: 1/1;
  background-color: var(--grey4);
  border-radius: var(--radius);

  @include for-mobile-screens {
    width: 147px;
    margin-bottom: 24px;
  }
}
