@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.tabs {
  height: 34px;
  width: 88px;
  border-radius: 20px;
  box-sizing: border-box;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  position: relative;

  * {
    z-index: 2;
  }
}

.hidden {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 28px;
  width: 44px;
  cursor: pointer;
  transition: color 0.15s ease-in;

  user-select: none; // prevent text from being highlighted on click
}

.glider {
  position: absolute;
  display: flex;
  height: 28px;
  width: 44px;
  background-color: var(--grey2);
  z-index: 1;
  border-radius: 30px;
  transition: 0.25s ease-out;

  .light & {
    background-color: var(--white);
  }
}

.optionA {
  &.active {
    & ~ .glider {
      transform: translateX(0);
    }
  }
}

.optionB {
  &.active {
    & ~ .glider {
      transform: translateX(100%);
    }
  }
}

.icon {
  height: 18px;
  width: auto;
  line-height: 18px;
  font-size: 18px;
  margin: 4px;
}
