@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.steps {
    display: flex;
    gap: 12px;

    li {
        display: flex;

        button {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--colorWhite);
            font-size: 14px;
            // display: inline-block;

            span {
                display: none;
            }
            
            &:after {
                content: '';
                width: 12px;
                height: 12px;
                background-color: var(--colorNavy20);
                border-radius: 50%;
            }
            
            &.active {
                background-color: var(--colorNavy);
                cursor: default;
                
                span {
                    display: inline;
                }
    
                &:after {
                    display: none;
                }
            }
        }
    }
}